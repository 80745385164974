import StatusValidation from './statusValidation';
const LiveURL			= "https://bend.muqala.com/"
const DevelopmentURL	= "https://qbend.converbot.com/"
const LocalURL			= "http://localhost:8081/"
const FinalURL			= LiveURL

export function PostBeforeLogin(type, userData) {
	return new Promise((resolve, reject) => {
		fetch(  FinalURL + type, {
			method: 'POST',
			body: JSON.stringify(userData),
			headers: { 'Content-Type': 'application/json' },
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				console.log(error);
				reject(error);
			});
	});
}

export function PostData(type, userData, formData = false) {
	let headers	=	{'Content-Type': 'application/json'}
	if( formData ){
		headers	= {}
	}
	return new Promise((resolve, reject) => {
		fetch( FinalURL + type, {
			method: 'POST',
			body: formData ? userData : JSON.stringify(userData),
			headers: headers,
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export function PostFormData(type, formData) {
	return new Promise((resolve, reject) => {
		fetch( FinalURL + type, {
			method: 'POST',
			body: formData,
			headers: {				
			},
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				console.log(error)
				reject(error);
			});
	});
}

export function GetData(type) {
	return new Promise((resolve, reject) => {
		fetch( FinalURL + type, {
			method: 'Get',
			headers: {
			},
		})
			.then((response) => StatusValidation(response))
			.then((responseJson) => {
				resolve(responseJson);
			})
			.catch((error) => {
				reject(error);
			});
	});
}
