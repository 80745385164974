import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Player from "./views/pages/Player"
import Conversation from './views/pages/Conversation';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path='/:id' element={<Player />} />
				<Route path='/conversation/:conversationid' element={<Conversation />} />
				<Route path="*" element={<Navigate to="/index" replace />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
